/* General overlay styling */
.post-detail-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Ensure it appears on top of other content */
}

/* PC version: Windowed layout */
.post-detail-container {
  background-color: #0a1b16;
  position: relative;
  /* padding: 20px; */
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 30px;
  border-radius: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  width: auto;
  max-width: 80%;
  min-width: 60%;
  max-height: 80%;
  /* min-height: 70%; */
  overflow: auto;

  /* Custom scrollbar */
  scrollbar-width: thin;
  scrollbar-color: #a99886 transparent;
}


.post-detail-container::-webkit-scrollbar {
  width: 10px;
}

.post-detail-container::-webkit-scrollbar-track {
  background: transparent;
}

.post-detail-container::-webkit-scrollbar-thumb {
  background: #a99886;
  border-radius: 8px;
}

.post-detail-container::-webkit-scrollbar-thumb:hover {
  background: #8b7262;
}

/* Wrapper for the image */

/* Wrapper for the image */
/* Wrapper for the image */
.post-image-wrapper {
  float: left; /* Float the image to the left */
  padding-right: 20px; /* Add spacing between the image and text */
  max-height: 50%;
  max-width: 50%; /* Constrain the width */
  margin-bottom: 20px; /* Add spacing below the image */
}

/* Text content styling */
.post-detail-content {
  padding-bottom: 10px;
  color: #bdcac6; /* Text color */
  font-family: 'Roboto', sans-serif;
}


.post-image-wrapper img {
  max-height: 100%; /* Ensure the image fits within the wrapper */
  max-width: 100%; /* Ensure the image fits within the wrapper */
  object-fit: cover; /* Maintain aspect ratio */
  border-radius: 10px;
}



.post-detail-content h2 {
  margin: 0;
  font-size: 1.5rem;
  color: #bdcac6; /* Text color */
}

.post-detail-content p {
  margin-top: 10px;
  line-height: 1.6;
  font-size: 1rem;
  color: #bdcac6; /* Text color */
  text-align: justify; /* Optional: Align text for a cleaner look */
}


/* Fixed bottom bar inside the container */
.post-detail-bottom-bar {
  position: sticky; /* Ensure it stays within the container */
  bottom: 0;
  left: 0;
  min-width: 70%;
  /* width: 100%; */
  height: 60px;
  padding-bottom: 10px;
  background: #0a1b16;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  /* box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.5);  */
  z-index: 1000;
}

.post-detail-bottom-bar button {
  background: none;
  border: none;
  color: #bdcac6;
  font-size: 1.5rem;
  cursor: pointer;
}

.post-detail-bottom-bar button:hover {
  color: #fff; /* Highlight on hover */
}

.post-detail-bottom-bar .edit-button {
  font-size: 1rem;
  color: #bdcac6;
  cursor: pointer;
  text-transform: uppercase;
}




/* Phone-specific styles */
@media (max-width: 600px) {
  .post-detail-container {
    padding: 5px;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    /* padding: 0; */
    border-radius: 0;
    /* overflow: hidden; */
  }

  .post-image-wrapper {
    width: 100%;
   
    max-width: 100%;
    max-height: 100%; /* Constrain the image height */
    padding: 0;
    margin: 0;
  }

  .post-image-wrapper img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 0;
  }

  .post-detail-content {
    padding: 5px;
    color: #bdcac6;
    font-family: 'Roboto', sans-serif;
    text-align: left;   
     padding-bottom: 100px;

  }

  .post-detail-content h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }

  .post-detail-content p {

    font-size: 1rem;
    line-height: 1.6;
  }

  .post-detail-bottom-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background: #0a1b16;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.5);
  }

  .post-detail-bottom-bar button {
    background: none;
    border: none;
    color: #bdcac6;
    font-size: 1.5rem;
    cursor: pointer;
  }

  .post-detail-bottom-bar button:hover {
    color: #fff;
  }

  .post-detail-bottom-bar .edit-button {
    font-size: 1rem;
    color: #bdcac6;
    cursor: pointer;
    text-transform: uppercase;
  }
}