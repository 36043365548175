.background-container {
  width: 100%;
  height: 100%;
  background-color: #fff; /* Set a background color if needed */
  overflow: hidden;
  position: relative;
}

.background-image {
  width: 100%;
  height: 120%;
  position: fixed;
  top: 0;
  left: 0;
  background-size: cover;
  z-index: -1;
}

.load-more-button {
  background: #8f8969;
  border: 3px solid #e1d6c3;
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: nunito,roboto,proxima-nova,"proxima nova",sans-serif;
  font-size: 16px;
  font-weight: 800;
  line-height: 16px;
  min-height: 40px;
  outline: 0;
  padding: 12px 14px;
  text-align: center;
  text-rendering: geometricprecision;
  text-transform: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
}

.load-more-button:hover,
.load-more-button:active {
  background-color: initial;
  background-position: 0 0;
  color: #e1d6c3;
}

.load-more-button:active {
  opacity: .5;
}
.whole-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  
}
@media (max-width: 600px) {
.post-container-limit {
  overflow: hidden;
  word-break: break-word; 
}}
.search-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  transition: width 0.3s ease;
}

.search-container.open {
  width: 200px;
}

.search-icon {
  cursor: pointer;
  font-size: 24px;
}

.search-input {
  width: 100%;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-left: 10px;
  display: none;
}

.search-container.open .search-input {
  display: block;
}















@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap');

*,
*::before,
*::after {
  box-sizing: border-box;
}

.filter-container {
  position: fixed;
  bottom: 20px;
  left: 60px; /* Pushed more to the right */
  z-index: 1000;
}
@media (max-width: 600px) {
  .filter-container {
    position: fixed;
    bottom: 10px;
    left: 20px; /* Pushed more to the right */
    z-index: 1000;
  }}

.filter-button {
  padding: 16px; /* Increased padding to make the button bigger */
  border: none;
  border-radius: 50%;
  background-color: #ffeba7; /* Match the dropdown color */
  color: #102770;
  cursor: pointer;
  box-shadow: 0 0 25px rgba(255, 235, 167, 0.45);
  transition: all 200ms linear;
}

.filter-button:hover {
  background-color: #e0d6a7; /* Slightly darker shade for hover */
}

.filter-window {
  position: fixed; /* Changed to fixed to be in front of everything */
  bottom: 70px;
  left: 40px; /* Adjusted to align with the filter button */
  background-color: #ffeba7; /* Match the dropdown color */
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: none;
  opacity: 0;
  pointer-events: none;
  transform: translateY(20px);
  transition: all 200ms linear;
  z-index: 1001; /* Ensure it is in front of everything */
}

.filter-container.open .filter-window {
  display: block;
  opacity: 1;
  pointer-events: auto;
  transform: translateY(0);
}

.post-detail-overlay ~ .filter-container {
  display: none; /* Hide filter when PostDetail is open */
}

.filter-window .custom-dropdown {
  position: relative;
  display: inline-block;
  width: 220px; /* Adjust width as needed */
}

.filter-window .dropdown-label {
  position: relative;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 13px; /* Reduced font size to make the label smaller */
  line-height: 2;
  height: 40px; /* Reduced height to make the label smaller */
  transition: all 200ms linear;
  border-radius: 4px;
  width: 100%;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: #ffeba7;
  cursor: pointer;
  color: #102770;
  box-shadow: 0 12px 35px 0 rgba(255, 235, 167, 0.15);
}

.filter-window .section-dropdown {
  position: absolute;
  padding: 5px;
  background-color: #111;
  bottom: 100%; /* Change to open upwards */
  left: 0;
  width: 100%;
  border-radius: 4px;
  display: block;
  box-shadow: 0 14px 35px 0 rgba(9, 9, 12, 0.4);
  z-index: 2;
  opacity: 0;
  pointer-events: none;
  transform: translateY(20px);
  transition: all 200ms linear;
}

.filter-window .dropdown-label:hover + .section-dropdown,
.filter-window .section-dropdown:hover {
  opacity: 1;
  pointer-events: auto;
  transform: translateY(0);
}

.filter-window a {
  position: relative;
  color: #fff;
  transition: all 200ms linear;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 15px;
  border-radius: 2px;
  padding: 5px 0;
  padding-left: 20px;
  padding-right: 15px;
  margin: 2px 0;
  text-align: left;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filter-window a:hover {
  color: #102770;
  background-color: #ffeba7;
}

.selected-tag {
  margin: 10px 0;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #ffeba7;
  color: #102770;
  box-shadow: 0 12px 35px 0 rgba(255, 235, 167, 0.15);
}

.search-button {
  padding: 10px 10px; /* Increased padding for a larger button */
  border: none;
  border-radius: 4px;
  background-color: #007bff; /* Match the filter button color */
  color: white;
  cursor: pointer;
  box-shadow: 0 0 25px rgba(255, 235, 167, 0.45);
  transition: all 200ms linear;
}

.search-button:hover {
  background-color: #0056b3; /* Slightly darker shade for hover */
}